import styled from "styled-components";
import { Color, Font, breakpoints , ColorDark} from "@/shared/Constants";
import { cardMixin } from "../mixin"; 


export const ParentGrid = styled.div`
  gap: 20px;
  margin:auto;
  width: max-content;
  grid-template-rows: inherit;
  display: grid;
  > div{
    padding-bottom: 0;
    &:not(:last-child)::before{
      right: -10px;
    }
      article{
        background:#F1F1F1;
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          background: ${ColorDark.backDarknew};
          }
        }
      }
    }
    grid-template-columns: repeat(4, 314px);
    .div1 { grid-area: 1 / 1 / 6 / 2; }
    .div2 { grid-area: 1 / 2 / 6 / 3; }
    .div3 { grid-area: 1 / 3 / 6 / 4; }
    .div4 { grid-area: 1 / 4 / 6 / 5; }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    > div{
          &:nth-child(n+3){
            article > {
              div{
                height:309px;
              }
            }
            .mt{
              margin: 10px auto;
              height: 140px;
              h2.title{
              font-size: 22px;
              line-height: 23px;
              margin-bottom: 5px;
            }
          .volanta{
            font-size: 22px;
            line-height: 23px;
          }
          .bajada{
            display:none;
          }
        }
        picture{
          height: 145px;
          width: 100%
        }
    }
}
    .div1 { grid-area: 1 / 1 / 5 / 2; }
    .div2 { grid-area: 1 / 2 / 5 / 3; }
    .div3 { grid-area: 1 / 3 / 3 / 4; }
    .div4 { grid-area: 3 / 3 / 5 / 4; }
  }

  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100% ;
    padding:0 ;
  }
`


export const Card = styled.div`
  ${cardMixin}
  height: fit-content;
`;